import React from 'react';
import { SnackbarProvider } from 'notistack';
import Auth from './container/auth';
import Dashboard from './container/dashboard';

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        preventDuplicate={true}
      >
        <main>
          <Auth />
          <Dashboard />
        </main>
      </SnackbarProvider>
    </div >
  );
}

export default App;
