import React, { useState, useEffect, useContext, useMemo, createContext } from "react";
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset, onAuthStateChanged } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence, collection as getCollection, addDoc, getDocs } from 'firebase/firestore';
import "firebase/auth";
import "firebase/firestore";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appID: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
});

const auth = getAuth(app);

enableIndexedDbPersistence(getFirestore(app)).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.error('Multiple tabs open, persistence can only be enabled in one tab at a a time.')
  } else if (err.code === 'unimplemented') {
    console.error('Browser does not support firebase persistence')
  }
});

const authContext = createContext();

export const useDatabase = () => {
  const [collection, setCollection] = useState([])
  const { user } = useAuth();
  const firestoreDb = getFirestore(app);

  const dbCollection = useMemo(
    () => { if (user.uid) return getCollection(firestoreDb, user.uid) },
    [user, firestoreDb],
  )

  useEffect(() => {
    if (dbCollection) {
      getDocs(dbCollection).then(docs => {
        let tmpCollection = collection;
        docs.forEach(doc => {
          if (doc.exists && !includes(keys(collection), doc.id)) {
            tmpCollection[doc.id] = doc.data();
          }
        });
        setCollection(tmpCollection);
      })
    }
  }, [dbCollection, collection])

  const add = data => {
    addDoc(dbCollection, data).then(docRef => {
      console.log("new auth added", docRef)
    })
  }

  return {
    collection: values(collection),
    add,
  }
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState({});

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then(response => {
      setUser(response.user);
      return response.user;
    });
  };

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).then(response => {
      setUser(response.user);
      return response.user;
    });
  };

  const signout = () => {
    return auth.signOut().then(() => {
      setUser(false);
    });
  };

  const sendPasswordResetMail = email => {
    return sendPasswordResetEmail(auth, email).then(() => {
      return true;
    });
  };

  const passwordReset = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword).then(() => {
      return true;
    });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetMail,
    passwordReset
  };
}