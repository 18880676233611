import React, { useState, useEffect, useCallback } from "react";
import Clipboard from "react-clipboard.js";
import Image from "react-bootstrap/Image";
import { useSnackbar } from 'notistack';
import { getAuthenticatorKey } from "../utils/authenticator";
import { getLogo } from "../utils/icons";

const AuthCard = ({ entry, seconds }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = useState('');

  useEffect(() => {
    const newCode = getAuthenticatorKey(entry.secret);
    setCode(newCode);
  }, [seconds, entry.secret])

  const toast = useCallback(
    ({ text }) => {
      return enqueueSnackbar(`${text} copied to clipboard!`);
    },
    [enqueueSnackbar],
  )

  return (
    <Clipboard
      data-clipboard-text={code}
      component="span"
      className="card"
      key={entry.secret}
      onSuccess={toast}
    >
      <div className="d-flex">
        <h2 style={{ flex: 1 }}>
          {String(code)
            .replace(/(\d{3})/g, "$1 ")
            .replace(/(^\s+|\s+$)/, "")}
        </h2>
        <span style={{ lineHeight: 2.25 }}>{seconds}</span>
      </div>
      <div className="d-flex">
        <span style={{ flex: 1 }}>
          <p>{entry.label}</p>
          <p>{entry.issuer}</p>
        </span>
        <Image
          src={getLogo(entry.issuer)}
          style={{ width: '2rem', height: '2rem' }}
        />
      </div>
    </Clipboard>
  );
};

export default AuthCard;
