import React, { useState } from 'react';
import get from 'lodash/get';
import { Button, Form } from "react-bootstrap";
import { useAuth } from '../utils/firebaseAuth';

const Auth = () => {
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('khareemnurulla@gmail.com');
  const [password, setPassword] = useState('');
  const { signin, user } = useAuth();


  return !user && (
    <Form
      style={{ textAlign: 'center' }}
      onSubmit={e => {
        e.preventDefault();
        signin(email, password);
      }}>
      {/* <Form.Group>
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={e => {
            setEmail(get(e, 'target.value'));
          }}
        />
      </Form.Group> */}

      <Form.Group>
        <Form.Control
          style={{ textAlign: 'center' }}
          type="password"
          placeholder="***"
          onChange={e => {
            setPassword(get(e, 'target.value'));
          }} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Login
      </Button>
    </Form>
  )
}

export default Auth;
