import { useState, useEffect, useCallback } from "react";
import authenticator from "authenticator";
import OTPAuth from "otpauth";
import get from "lodash/get";

export const getAuthenticatorKey = (token) =>
  authenticator.generateToken(token);

export const parseOtpUri = (uri) => {
  let totp = {};

  try {
    totp = OTPAuth.URI.parse(uri);
  } catch (error) {
    // console.log('error in parseOtpUri', error)
  }

  return {
    issuer: get(totp, "issuer", ""),
    label: get(totp, "label", ""),
    secret: get(totp, "secret.b32", ""),
    algorithm: get(totp, "algorithm", ""),
    digits: get(totp, "digits", ""),
    period: get(totp, "period", ""),
  };
};

export const getTimeRemaining = () => {
  const epoch = Math.round(new Date().getTime() / 1000.0);
  const countDown = 30 - (epoch % 30);
  return Math.max(countDown, 0);
};

let interval = null;

export const useRemaining = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(getTimeRemaining());

  const tick = useCallback(() => {
    const timeRemaining = getTimeRemaining();
    setSecondsRemaining(timeRemaining);
  }, [setSecondsRemaining]);

  useEffect(() => {
    interval = setInterval(() => {
      tick();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [tick]);

  return {
    seconds: secondsRemaining,
  };
};
