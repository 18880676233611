import React, { useState } from 'react';
import map from 'lodash/map';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NewForm from '../components/newFragment';
import AuthCard from '../components/AuthCard';
import { useAuth } from '../utils/firebaseAuth';
import { useDatabase } from '../utils/firebaseAuth';
import { useRemaining } from "../utils/authenticator";

const Dashboard = () => {
  const [addingNew, setAddingNew] = useState(false);
  const { user, signout } = useAuth();
  const { seconds } = useRemaining();
  const { collection } = useDatabase();

  return user && (
    <React.Fragment>
      <Navbar bg="light" fixed="top" className="d-flex">
        <Navbar.Brand style={{ flex: 1, textAlign: 'left' }}>{user.email}</Navbar.Brand>
        <Nav.Link onClick={signout} className="orange">Logout</Nav.Link>
      </Navbar>

      {!addingNew && (<div className="grid">
        <div className="card add-new" onClick={() => setAddingNew(!addingNew)}>
          <h3>Add New &#43;</h3>
          <p>New service authenticator</p>
        </div>
        {
          map(collection, (entry, key) => {
            return <AuthCard entry={entry} key={key} seconds={seconds} />
          })
        }
      </div>)}
      {addingNew && <NewForm setAddingNew={setAddingNew} />}
    </React.Fragment>
  )
}

export default Dashboard;
