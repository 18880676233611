import React, { useState } from 'react';
import get from 'lodash/get';
import { Button, Form } from "react-bootstrap";
import { useDatabase } from '../utils/firebaseAuth';
import { parseOtpUri } from '../utils/authenticator';

const Auth = ({ setAddingNew }) => {
  const { add } = useDatabase();
  const [label, setLabel] = useState('');
  const [secret, setSecret] = useState('');
  const [issuer, setIssuer] = useState('');
  const [method, setMethod] = useState('totp');

  const saveToDatabase = () => {
    if (label &&
      secret &&
      issuer &&
      method) {
      add({
        label,
        secret,
        issuer,
        method
      });
      setAddingNew(false);
    } else {
      alert('All fields are required!')
    }
  }

  return (
    <Form
      style={{ textAlign: 'center' }}
      onSubmit={e => {
        e.preventDefault();
        saveToDatabase();
      }}
      onReset={e => {
        setAddingNew(false);
      }}
    >
      <Form.Control
        type="text"
        placeholder="Uri"
        onChange={e => {
          const value = e.target.value;
          const { label, secret, issuer } = parseOtpUri(value);
          if (label && secret && issuer) {
            setLabel(label);
            setSecret(secret);
            setIssuer(issuer);
          }
          // const value = e.target.value;
          // console.log('value', {
          //   value, queryString: queryString.parse(value, {
          //     parseNumbers: true,
          //   }), parseOtpUri: parseOtpUri(value)
          // })
        }}
      />
      <hr title="or" />
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Label"
          onChange={e => {
            setLabel(get(e, 'target.value'));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Secret"
          onChange={e => {
            setSecret(get(e, 'target.value'));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Issuer"
          onChange={e => {
            setIssuer(get(e, 'target.value'));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control as="select" value={method}
          onChange={e => {
            setMethod(get(e, 'target.value'));
          }}>
          <option value="totp">Time based</option>
          <option value="hotp">Counter based</option>
        </Form.Control>
      </Form.Group>
      <Button variant="secondary" type="reset" style={{ marginRight: '.5rem' }}>
        Cancel
      </Button>
      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  )
}

export default Auth;
